import axios from './fetch'


export function feilogin (data) {
  return axios({
    url: '/getCode',
    method: 'POST',
    data
  })
}
export function submit (data) {
  return axios({
    url: '/submit',
    method: 'POST',
    data
  })
}

export default {
  feilogin,
  submit
}