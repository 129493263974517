import axios from "axios"


let baseURL = '/website'
const instance = axios.create({
  baseURL: baseURL, //服务器地址
  timeout: 27000,
  headers: {}
})

// 请求拦截器：在请求被发送出去之前，做一些验证工作
instance.interceptors.request.use((config) => {
  config.headers.Authorization = localStorage.getItem('token') || ''
  return config
},
)
// 响应拦截器：在响应到达之前，先进行数据过滤，错误处理
instance.interceptors.response.use(function (response) {
  if (response.data && response.data.code == 0) {
    return response.data.data
  } else {
    alert('网络异常，请求稍后再试')
  }
}, function (error) {
  return Promise.reject(error)
})

export default instance