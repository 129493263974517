<template>
  <div>
    <vue-nav activeIndex='index'></vue-nav>
    <div class="home-box">
      <!--  轮播图 -->
      <div class="section1">
        <div class="swiper-container swiper1">
          <div class="swiper-wrapper">
            <div v-for="item of swiperList" :key="item.id" class="swiper-slide">
              <img class="swiper-img" :src="item.imgUrl" alt="">
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev swiper-button-white"></div>
          <div class="swiper-button-next swiper-button-white"></div>
        </div>
      </div>
      <!-- 经营场景 -->
      <div class="section2">
        <h2>适用于多种经营场景</h2>
        <img class="tu" :src="section2[sect2].url" alt="">
        <div class="lis">
          <div v-for="(item,key) in section2" :key="item.id" class="ise-box">
            <div class="ise" @mouseenter="section2Enter(key)">
              <img :src="item.id==(sect2+1)?item.urli:item.urls" alt="" class="color">
              <p>{{item.title}}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 一体化美业解决方案 -->
      <div class="section3">
        <img src='../assets/image/news3.png' alt="">
        <div class="sect3">
          <span @click="tonews()">查看详情>></span>
        </div>
      </div>
      <!--  特殊功能介绍 -->
      <div class="section4">
        <h2>特色功能介绍</h2>
        <div class="section4-left">
          <div class="sec4left1" @mouseenter="section4Enter(0)">
            <img v-if="sect4!=0" src="../assets/image/sect4-1.png" alt="">
            <img class="im" src="../assets/image/sect4-11.png" alt="" v-if="sect4==0">
          </div>
          <div class="sec4left2" @mouseenter="section4Enter(1)">
            <img v-if="sect4!=1" src="../assets/image/sect4-2.png" alt="">
            <img class="im" src="../assets/image/sect4-22.png" alt="" v-if="sect4==1">
          </div>
          <div class="sec4left3" @mouseenter="section4Enter(2)">
            <img v-if="sect4!=2" src="../assets/image/sect4-3.png" alt="">
            <img class="im" src="../assets/image/sect4-33.png" alt="" v-if="sect4==2">
          </div>
          <div class="sec4left4" @mouseenter="section4Enter(3)">
            <img v-if="sect4!=3" src="../assets/image/sect4-4.png" alt="">
            <img class="im" src="../assets/image/sect4-44.png" alt="" v-if="sect4==3">
          </div>
          <div class="sec4left5" @mouseenter="section4Enter(4)">
            <img v-if="sect4!=4" src="../assets/image/sect4-5.png" alt="">
            <img class="im" src="../assets/image/sect4-55.png" alt="" v-if="sect4==4">
          </div>
          <div class="sec-one" v-for="(item,key) in section4" :key='key'>
            <img :src="item.url" alt="" v-show="sect4===key">
          </div>
        </div>
        <div class="section4-right">
          <div class="s4r" v-for="(item,key1) in section4" :key='key1'>
            <div v-for="(val,key) in item.list" :key='key' v-show="sect4==key1">
              <h3>{{val.title}}</h3>
              <span>{{val.text}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 试用 -->
      <div class="section5">
        <h2>美业汇全方位深入解决美业门店经营管理难题 </h2>
        <h2>实现智慧门店管理，赋能业绩提升</h2>
        <div class="inp">
          <el-input class="isinput" v-model="input" placeholder="请输入手机号"></el-input>
          <el-button @click="open()">免费试用</el-button>
        </div>
      </div>
      <!-- 选择美业汇的6大理由 -->
      <div class="section6">
        <h2>选择美业汇的<span> 6 </span>大理由 </h2>
        <img src="../assets/image/sect6-bg.png" alt="">
      </div>
      <!-- 案例 -->
      <div class="section7">
        <h2>3000+美业门店的共同选择 </h2>
        <div class="banan">
          <div class="swiper-container swiper2">
            <div class="swiper-wrapper">
              <div v-for="(item ,key) of swiperList1" :key="key" class="swiper-slide">
                <img class="swiper-img" :src="item.imgUrl" alt="">
              </div>
            </div>
            <div class="swiper-button-prev swiper-button-black"></div>
            <div class="swiper-button-next swiper-button-black"></div>
          </div>
        </div>
        <img class="rts" src="../assets/image/rtd.png" alt="">
      </div>
      <!-- 服务和保障 -->
      <div class="section8">
        <h2>服务和保障</h2>
        <ul class="set8list">
          <li>
            <h3>服务保障</h3>
            <p>保证不动老板的钱</p>
            <p>保证不动老板的顾客</p>
          </li>
          <li>
            <h3>售后保障</h3>
            <p>国内最专业保障，支持到店落地培训 </p>
            <p>一对一售后跟踪，客户满意度100%</p>
          </li>
          <li>
            <h3>技术保障</h3>
            <p>系统每周更新一次，</p>
            <p>技术保障接受商家需求反馈，</p>
            <p>不断升级迭代和完善产品</p>
          </li>
          <li>
            <h3>运营保障</h3>
            <p>分析诊断门店经营状况</p>
            <p>量身定制门店营销方案</p>
          </li>
          <li class="two2">
            <h3>安全保障</h3>
            <p>阿里云云端数据存储，</p>
            <p>签订商业安全协议，</p>
            <p>为用户数据提供金融级安全保障</p>
          </li>
          <li>
            <h3>效率保障</h3>
            <p>保证顾客管理、员工管理、</p>
            <p> 门店管理效率成倍提升</p>
          </li>
          <li>
            <h3>服务保障</h3>
            <p>保证去掉中间层，</p>
            <p>使管理成本大幅度降低</p>
          </li>
        </ul>
      </div>
      <!-- 验证 -->
      <el-dialog title="服务热线：136-0200-1885" :visible.sync="centerDialogVisible" width="800px" class="islog">
        <p></p>
        <h2>免费试用</h2>
        <h3>请准确填写您的信息，方便安排专业顾问和您联系！</h3>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="您的姓名" prop="realname">
            <el-input v-model="ruleForm.realname" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input class="isdesc" v-model="ruleForm.code" placeholder="请输入验证码"></el-input>
            <el-button type="primary" @click="submit" class="paics">发送验证码</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          </el-form-item>
        </el-form>
        <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span> -->
      </el-dialog>
    </div>
  </div>
</template>
<script>
import bnav from './components/b-nav'

import Swiper from 'swiper'
export default {
  name: 'index',
  components: {
    'vue-nav': bnav,
  },
  data () {
    return {
      // 轮播，使用变量循环
      swiperList: [
        {
          id: '001',
          imgUrl: require('../assets/image/baner1.png')
        },
        {
          id: '002',
          imgUrl: require('../assets/image/baner2.png')
        },
        {
          id: '003',
          imgUrl: require('../assets/image/baner3.png')
        },
        {
          id: '004',
          imgUrl: require('../assets/image/baner4.png')
        },
      ],
      section2: [
        { id: 1, url: require('../assets/image/btf-1.png'), urls: require('../assets/image/sy1.png'), urli: require('../assets/image/sy11.png'), title: '美容管理' },
        { id: 2, url: require('../assets/image/beau-1.png'), urls: require('../assets/image/sy2.png'), urli: require('../assets/image/sy22.png'), title: '美容美体' },
        { id: 3, url: require('../assets/image/Beauty-1.png'), urls: require('../assets/image/sy3.png'), urli: require('../assets/image/sy33.png'), title: '皮肤管理' },
        { id: 4, url: require('../assets/image/px-1.png'), urls: require('../assets/image/sy4.png'), urli: require('../assets/image/sy44.png'), title: '美容培训' },
        { id: 5, url: require('../assets/image/cosmetics-1.png'), urls: require('../assets/image/sy5.png'), urli: require('../assets/image/sy55.png'), title: '化妆造型' },
        { id: 6, url: require('../assets/image/slimming-1.png'), urls: require('../assets/image/sy6.png'), urli: require('../assets/image/sy66.png'), title: '瘦身纤体' },
      ],
      section4: [
        {
          url: require('../assets/image/sect4-111.png'), list: [
            { title: '端口业务化', text: '各端口各功能模块都是按照直营体系独一无二的业务模式、 业务流程及工作内容打造及呈现的。' },
            { title: '数据业务化', text: '系统所有呈现出来的数据都是为了更精准的指导业务、服务于业务。' },
            { title: '财务业务化', text: '财务管理完全是业务型财务管理，除了管好钱管好货，还要把卖卡管规范， 把业绩结构管合理管健康，保证不乱卖不卖乱。' },
            { title: '模式业务化', text: '系统基于28年直营连锁美容院业务模式、管理经验精雕细琢，确保顾客价值 提升、员工价值提升、店价值提升，打造盈利多、价值高、时间久的产业互联网美容院。' }
          ],
        },
        {
          url: require('../assets/image/sect4-222.png'), list: [
            { title: '在线商城', text: '支持顾客24小时线上参与营销活动、购买商品、查询订单。 ' },
            { title: '在线查阅', text: 'APP支持员工及管理层、店老板随时查阅顾客档案、预约、线上线下销售 消耗信息。' },
            { title: '在线培训', text: '支持员工在线通过员工天地内图片、文档、视频、微课堂学习专业知识，提升 专业技能，以便更好的为顾客服务并提升销售能力。' },
            { title: '在线指导', text: '管理层、店老板通过APP四表四看模块及架构模块，实时精准指导各层级 开展各项业务工作，打造高价值美容院体系。' },
          ]
        },
        {
          url: require('../assets/image/sect4-333.png'), list: [
            { title: '一键问题顾客查询', text: '六类问题顾客一键查询，构建动态精准的高粘性会员管理体系，提高顾客 管理效率，精准提升顾客价值。 ' },
            { title: ' 一键沉睡顾客激活', text: '一键激活沉睡顾客，提升顾客返店率，保障顾客不流失。' },
            { title: '一键员工绩效查阅', text: '一键掌握员工三同步指标完成进度，员工预约人次数、加项目加产品以 及客回放指标，打造高价值员工体系。' },
            { title: '一键预约自动排班', text: '通过APP一键有目标有针对性的精准在线预约，实时五约提醒，合理 安排服务时间，提升预约效率。' },
          ]
        },
        {
          url: require('../assets/image/sect4-444.png'), list: [
            { title: '智能收银', text: ' 更便捷更高效的智能收银管理系统。极速开单收银、挂单结算、办卡充值、 跨店消费、智能核销、电子签名等，简单几个按键实现门店消费、消耗全场景覆盖' },
            { title: '智能管店', text: '员工、院长及管理干部通过四表四看模块，按照连锁直营美容院经营模式 及工作流程，流程化标准化精准的完成每天的各项工作。 ' },
            { title: '智能拓客', text: '扫码拓客、注册有礼带新客、优惠券分享拓新客、秒杀分享拓新客、推荐 有礼带嘉宾、限时拼团带嘉宾等多种零成本拓客方式，快速获得精准的完成每天的各项工作。 ' },
            { title: '智能分析', text: '门店经营数据深度实时动态精准呈现，管理层运用数据精准决策，打造高 价值美容院体系。  ' },
          ]
        },
        {
          url: require('../assets/image/sect4-555.png'), list: [
            { title: '多端同步', text: '美业汇系统五个端口财务数据和业务数据完全打通，实现财务数据和业务 数据一体化，确保各项经营数据实时精准的呈现在各端口及各功能模块中提升业绩用。' },
            { title: '多店管理', text: '多店多端财务数据和业务数据一体化，动态实时同步，权限分层分岗位 设置，多店连锁管控，降低层级管理难度，高效便捷，成倍提升管理效率确保提升业绩 ' },
            { title: '多维联动', text: '营销三维度、管理四维度、时间五维度、会议六维度、业务动作各层级实 时可见，确保精准指导各业务层高效提升业绩。' },
            { title: '立体专属', text: '顾客、员工、管理层、店老板、门店五位一体化立体呈现在美业汇系统中， 是一款独一无二深耕美业的专属美业产业互联网店务管理系统。' },
          ]
        },
      ],
      swiper7: [
        { url: require('../assets/image/an2.png') },
        { url: require('../assets/image/anl.png') },
        { url: require('../assets/image/anl1.png') },
      ],
      swiperList1: [
        {
          id: '001',
          imgUrl: require('../assets/images/a1.png')
        },
        {
          id: '002',
          imgUrl: require('../assets/images/a2.png')
        },
        {
          id: '003',
          imgUrl: require('../assets/images/a3.png')
        },
      ],
      sect2: 0,
      sect4: 0,
      input: '',
      centerDialogVisible: false,
      ruleForm: {
        realname: '',
        mobile: '',
        code: ''
      },
      rules: {
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },

        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      }
    }
  },
  mounted () {
    new Swiper('.swiper1', {
      loop: true,
      // 如果需要分页器
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 0,
      autoplay: 3500,
      grabCursor: true,
      autoplayDisableOnInteraction: false,
      pagination: '.swiper-pagination',
      // 如果需要前进后退按钮
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
    })
    new Swiper('.swiper2', {
      loop: true,
      // 如果需要分页器
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 0,
      autoplay: 3500,
      grabCursor: true,
      autoplayDisableOnInteraction: false,
      pagination: '.swiper-pagination2',
      // 如果需要前进后退按钮
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
    })
  },
  methods: {
    section2Enter (key) {
      this.sect2 = key
    },
    section4Enter (key) {
      this.sect4 = key
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('发送成功');
          this.sub()
        }
      });
    },
    open () {
      this.centerDialogVisible = true
      this.ruleForm.mobile = this.input
    },
    sub () {
      var data = this.ruleForm
      console.log(data);

      this.$http.submit(data).then(res => {
        this.$message({
          message: res,
          type: 'success'
        });
        this.centerDialogVisible = false
      })

    },
    submit () {
      var data = {}
      data.mobile = this.ruleForm.mobile
      this.$http.feilogin(data).then(res => {
        this.$message({
          message: res,
          type: 'success'
        });
      })
    },
    tonews () {
      this.$router.push({
        path: '/news',
      })
    }
  }
}
</script>
<style lang="scss">
@import "../assets/index.scss";
</style>
