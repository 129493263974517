<template>
  <div class="home-box">
    <!-- 顶部导航栏 -->
    <!-- <vue-nav></vue-nav> -->
    <!-- 内容区 -->
    <router-view></router-view>
    <!-- 底部 -->
    <vue-footer></vue-footer>
  </div>

</template>
<script>
// import bnav from './views/components/b-nav'
import bfooter from './views/components/b-footer'
export default {
  name: 'App',
  components: {
    // 'vue-nav': bnav,
    'vue-footer': bfooter
  }
}
</script>

<style lang="scss">
@import "./assets/home.scss";
</style>
