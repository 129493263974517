<template>
  <div class="footer">
    <div class="footer-box">
      <div class="brand">
        <img src="../../assets/image/brand.png" class='imge'>
        <p>咨询热线</p>
        <div>
          <img src="../../assets/image/poto.png" alt="" class="po">
          <img src="../../assets/image/136-0200-1885.png" alt="" class="hoa">
        </div>
      </div>
      <div class="product">
        <h2>美业汇产品</h2>
        <div class="footer-sell">
          <span @click="tocp(1)">业务公众号</span>
          <span @click="tocp(3)">员工天地</span>
          <span @click="tocp(10)">电脑</span>
        </div>
        <div class="footer-sell">
          <span @click="tocp(9)">美好商城</span>
          <span @click="tocp(4)">员工微店</span>
          <span @click="tocp(5)">盈利宝</span>
        </div>
        <div class="footer-sell">
          <span @click="tocp(4)">APP</span>
          <span @click="tocp(7)">IPAD</span>
        </div>
      </div>
      <div class="about">
        <h2>关于我们</h2>
        <div class="footer-sell">
          <span @click="tome('#introduction')">公司介绍</span>
        </div>
        <div class="footer-sell">
          <span @click="tome('#course')">发展历程</span>
        </div>
        <div class="footer-sell">
          <span @click="tome('#contactUs')">联系我们</span>
        </div>
      </div>
      <div class="qr">
        <img src="../../assets/image/QR.png" alt="">
        <p>官方公众号</p>
      </div>
    </div>
    <div class="copyright">
      <a href="https://beian.miit.gov.cn/" target="_blank">
        备案序号： 粤ICP备15027448号
      </a>
      <span> 版权所有：Copyright © 2020 深圳市美丽范科技有限公司</span>
    </div>
  </div>

</template>
<script>

export default {
  name: 'b-footer',
  data () {
    return {
      activeIndex: '1',
    };
  },
  methods: {
    tome (val) {
      this.$router.push({
        path: '/about',
        hash: val
      })
    },
    tocp (val) {
      this.$router.push({
        path: '/product',
        query: {
          val: val
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/footer.scss";
</style>