import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
// import bus from './bus'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/index.scss'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
import 'fullpage.js/dist/fullpage.min.css' // 引入css
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'
Vue.use(ElementUI)
Vue.use(VueFullPage)
Vue.config.productionTip = false
import http from '@/utils/api'
Vue.prototype.$http = http

// import BaiduMap from 'vue-baidu-map';
// Vue.use(BaiduMap, {
//   /* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
//   ak: 'GKQHi0OSAmmCAU2E5NP8GnlFkFx5UpBI'
// })

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
